<template>
  <div class="formBody">
    <v-row align="center" style="width: 100vw">
      <v-row justify="space-around" style="margin: 0 0 0 0">
        <div>
          <v-dialog
              v-model="dialog"
              width="500"
          >
            <v-card>
              <v-card-title
                  class="headline primary"
                  primary-title
              >
                {{ this.$t('dialog.dataFound.headline') }}
              </v-card-title>

              <v-card-text class="mt-3">
                {{ this.$t('dialog.dataFound.body') }}
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="error"
                    text
                    @click="clearRecoverData()"
                >
                  {{ this.$t('button.delete') }}
                </v-btn>
                <v-btn
                    color="primary"
                    @click="recoverData()"
                >
                  {{ this.$t('button.restore') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <h1>Advocard <strong>Private</strong></h1>
          <v-progress-linear v-if="isLoading" color="primary" indeterminate></v-progress-linear>
          <v-form
              ref="form"
              v-model="valid"
              :lazy-validation="lazy"
              class="form"
          >
            <v-container style="min-width: 25vw" v-on:input="save()">
              <v-card :color="documentcheck ? '' : 'transparent'" flat >

                <v-card-text :class="documentcheck ? 'mb-3' : 'pa-0'">
                  <v-row>
                    <v-col>
                      <v-checkbox v-on:change="checkForPermission()" v-model="documentcheck" :label="inputLabels.document">
                      </v-checkbox>
                    </v-col>
                    <v-col>
                      <v-checkbox v-on:change="autoUpdateProcessSelf('DokumentenCheck')" v-if="documentcheck && $RStore.user.permissions.insurance.advocardPrivateDataType.Dokumentencheck" v-model="processSelf" :disabled="processSelfDisabled" :label="inputLabels.processSelf"></v-checkbox>
                    </v-col>
                    <v-col v-if="documentcheck" cols="12">
                      <v-textarea
                          v-model="notes"
                          :counter="300"
                          :label="inputLabels.notes"
                          :rules="notesRules"
                          auto-grow
                          required
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-card-text>

              </v-card>
              <v-card :color="coaching ? '' : 'transparent'" v-if="!shuttlemediation && $RStore.user.permissions.insurance.advocardPrivateDataType.VermittlungCoaching" flat >
                <v-card-text :class="coaching ? 'mb-3' : 'pa-0'">
                  <v-row>
                    <v-col>
                      <v-checkbox v-on:change="checkForPermission()" v-model="coaching" :label="inputLabels.coaching">
                      </v-checkbox>
                    </v-col>
                    <v-col>
                      <v-checkbox v-on:change="autoUpdateProcessSelf('Coaching')"  v-if="coaching && $RStore.user.permissions.insurance.advocardPrivateDataType.Coaching" v-model="processSelfCoaching" :disabled="processSelfCoachingDisabled" :label="inputLabels.processSelfCoaching"></v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>

              </v-card>
              <v-card v-if="!coaching" :color="shuttlemediation ? '' : 'transparent'" flat >
                <v-card-text :class="shuttlemediation ? '' : 'pa-0'">
                  <v-row v-if="$RStore.user.permissions.insurance.advocardPrivateDataType.VermittlungMediation">
                    <v-col>
                      <v-checkbox v-model="shuttlemediation" :label="inputLabels.shuttlemediation">
                      </v-checkbox>
                    </v-col>
                    <v-col>
                      <v-checkbox v-if="shuttlemediation && $RStore.user.permissions.insurance.advocardPrivateDataType.Mediation"  v-model="processSelfMediation" :label="inputLabels.processSelfMediation"></v-checkbox>
                    </v-col>
                  </v-row>
                  <div v-if="shuttlemediation">
                    <v-row>
                      <v-col cols="12">
                        <v-textarea
                            v-model="notesSM"
                            :counter="300"
                            :label="inputLabels.notesSM"
                            :rules="notesRules"
                            auto-grow
                            required
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </div>
                </v-card-text>
              </v-card>
              <v-card v-if="fachberatungVisible && $RStore.user.permissions.insurance.advocardPrivateDataType.VermittlungFachberatung" :color="fachberatung ? '' : 'transparent'" flat :class="fachberatung ? 'mt-3' : ''">
                <v-card-text :class="fachberatung ? '' : 'pa-0'">
                  <v-row v-if="$RStore.user.permissions.insurance.advocardPrivateDataType.VermittlungFachberatung">
                    <v-col>
                      <v-checkbox v-model="fachberatung" :label="inputLabels.fachberatung">
                      </v-checkbox>
                    </v-col>
                    <v-col>
                      <v-checkbox v-if="fachberatung && $RStore.user.permissions.insurance.advocardPrivateDataType.Fachberatung"  v-model="fachberatungProcessSelf" :label="inputLabels.fachberatungProcessSelf"></v-checkbox>
                    </v-col>
                  </v-row>
                  <div v-if="fachberatung">
                    <v-row>
                      <v-col cols="12">
                        <v-textarea
                            v-model="notesFB"
                            :counter="300"
                            :label="inputLabels.notesFB"
                            :rules="notesRules"
                            auto-grow
                            required
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </div>
                </v-card-text>
              </v-card>
              <!-- form if documentcheck, coaching, fachberatung oder shuttlemediation -->
              <v-card class="mt-3" v-if="documentcheck || coaching || shuttlemediation || fachberatung || chevalierVermittlung">
                <v-card-title>Kontaktinformationen</v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                          v-model="email"
                          :label="inputLabels.email"
                          :rules="emailRules"
                          required
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                          v-model="phone"
                          :label="inputLabels.phone"
                          :rules="phoneRules"
                          required
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-checkbox
                          v-model="sms"
                          :label="inputLabels.sms"
                          required
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <!-- Form -->
              <v-row>
                <v-col>
                  <v-text-field
                      :label="inputLabels.policyNumber"
                      :rules="policyNumberRules"
                      v-if="hideNumbers(serviceNumber)"
                      v-model="policyNumber"
                  ></v-text-field>
                </v-col>

              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                      v-model="serviceNumber"
                      :label="inputLabels.serviceNumber"
                      :rules="serviceNumberRules"
                      required
                      v-if="hideNumbers(policyNumber)"
                  ></v-text-field>
                </v-col>

              </v-row>

              <v-row>
                <v-col
                    cols="12"
                    md="6"
                >
                  <v-text-field
                      v-model="name"
                      :label="inputLabels.name"
                      :rules="nameRules"
                      required
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="6"
                >
                  <v-text-field
                      v-model="lastName"
                      :label="inputLabels.lastName"
                      :rules="nameRules"
                      required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-autocomplete
                  v-model="activityType"
                  :items="la"
                  :label="inputLabels.activityType"
                  :placeholder="inputLabels['insuranceSearch']"
                  :rules="activityTypeRules"
                  color="primary"
                  hide-no-data
                  hide-selected
                  item-text="name"
                  item-value="id"
                  required
                  return-object
                  @change="checkForFachberatung()"
              ></v-autocomplete>
              <v-chip color="primary" v-if="fachberatungVisible  && $RStore.user.permissions.insurance.advocardPrivateDataType.VermittlungFachberatung">
                Eine Fachberatung ist für diese Leistungsart möglich
              </v-chip>
              <v-card v-if="$store.state.allowedAdvocardServiceTypesForChevalier.includes(activityType.id) && $RStore.user.permissions.insurance.advocardPrivateDataType.ChevalierVermittlung" :color="chevalierVermittlung ? '' : 'transparent'" flat >
                <v-card-text :class="chevalierVermittlung ? 'mb-3' : 'pa-0'">
                  <v-row>
                    <v-col>
                      <v-checkbox v-on:change="checkForPermission()" v-model="chevalierVermittlung" label="Chevalier Vermittlung">
                      </v-checkbox>
                    </v-col>
                    <v-col v-if="chevalierVermittlung" cols="12">
                      <v-textarea
                          v-model="submitReport"
                          :counter="8000"
                          color="primary"
                          label="Übermittlungsbericht"
                          :rules="reportRules"
                          auto-grow
                          required
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-textarea
                  v-model="issue"
                  :counter="500"
                  :label="inputLabels.issue"
                  :rules="issueRules"
                  auto-grow
                  required
              ></v-textarea>

              <v-menu
                  v-model="consultationDateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  min-width="290px"
                  offset-y
                  transition="scale-transition"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      :label="inputLabels['consultationDate']"
                      :value="formatDate(consultationDate)"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="consultationDate" color="primary" show-current
                               @input="saveConsultationDate()"></v-date-picker>
              </v-menu>

            </v-container>

            <v-row
                class="mb-10"
                justify="end"
                md="4"
            >
              <v-btn
                  class="mr-4"
                  color="error"
                  @click="$router.back()"
              >
                {{ this.$t('button.cancel') }}
              </v-btn>

              <v-btn
                  :disabled="blockButtonWhileRequestAndForm()"
                  class="mr-4"
                  color="success"
                  @click="validate"
              >
                {{ this.$t('button.sent') }}
              </v-btn>
            </v-row>
          </v-form>
        </div>
      </v-row>
    </v-row>
  </div>
</template>

<script>
import i18n from "../../../../plugins/i18n";
import {getAdvocardPrivateURL, requestMode} from "@/configBuilder";
import {sessionHandler} from "@/request/sessionHandler";
import {showAlert} from "@/utils/alertHandler";
import dateFormatter from "../../../../utils/dateFormatter";
import reactiveStorage from "@/plugins/reactiveStorage";

export default {
  name: "AdvocardPrivate",
  data: () => ({
    valid: true,
    isLoading: false,
    policyNumber: '',
    name: '',
    email: '',
    free: false,
    chevalierVermittlung: false,
    notes: '',
    submitReport: "",
    phone: '+49',
    serviceNumberCanBeNull: false,
    policyNumberCanBeNull: false,
    serviceNumberChecked: false,
    policyNumberChecked: false,
    fachberatungProcessSelf: false,
    fachberatung: false,
    fachberatungVisible: false,
    notesFB: '',
    documentcheck: false,
    coaching: false,
    shuttlemediation: false,
    processSelf: false,
    processSelfMediation: false,
    activityType: {id: "77", name: "Beratungsrechtschutz"},
    namePolicyNumber: '',
    issue: '',
    notesSM: '',
    lastName: '',
    serviceNumber: '',
    lazy: false,
    consultationDate: new Date().toISOString().substr(0, 10),
    consultationDateMenu: false,
    dialog: false,
    sms: true,
    processSelfCoaching: false,
    processSelfDisabled: false,
    processSelfCoachingDisabled: false,
    la: [
      {id: "1", name: "Kfz Straf-RS"},
      {id: "2", name: "Allgemeiner Straf-RS (privat)"},
      {id: "3", name: "Kfz Schadenersatz-RS"},
      {id: "4", name: "Allgemeiner Schadenersatz-RS"},
      {id: "5", name: "Kfz Vertrags-RS"},
      {id: "6", name: "Allgemeiner Vertrags-RS"},
      {id: "7", name: "Familien- und Erb-RS"},
      {id: "8", name: "Wohnungs-RS"},
      {id: "9", name: "Vermieter-RS (zu Wohnzwecken)"},
      {id: "10", name: "Gewerbe-RS (Eigentum/Miete)"},
      {id: "11", name: "Arbeits-RS"},
      {id: "12", name: "Sozial-RS"},
      {id: "13", name: "Finanzgerichts-RS"},
      {id: "14", name: "Vermögensschaden-RS"},
      {id: "15", name: "OWI-RS (fließender Verkehr)"},
      {id: "16", name: "OWI-RS (ruhender Verkehr)"},
      {id: "17", name: "Firmen-Vertrags-RS"},
      {id: "18", name: "Verwaltungs-RS"},
      {id: "19", name: "Vermieter-RS (gew.Objekt)"},
      {id: "20", name: "Disziplinar-u.Standes-RS"},
      {id: "21", name: "Fußgänger Straf-RS"},
      {id: "22", name: "Spezial-Straf-RS"},
      {id: "23", name: "Spezial-Straf-RS XL"},
      {id: "24", name: "Allgemeiner Straf-RS (gew.)"},
      {id: "25", name: "Allgemeiner Owi-RS"},
      {id: "28", name: "Verwaltungs-RS (Verkehr)"},
    ],
  }),

  methods: {
    checkForPermission() {
      if (reactiveStorage.user.permissions.insurance.advocardPrivateDataType.Dokumentencheck) {
        if (this.coaching) {
          if (reactiveStorage.user.permissions.insurance.advocardPrivateDataType.Coaching) {
            this.processSelfDisabled = false
            this.processSelfCoachingDisabled = false
          } else {
            this.processSelfDisabled = true
            this.processSelfCoachingDisabled = true
            this.processSelf = false
            this.processSelfCoaching = false
          }
        } else {
          this.processSelfDisabled = false
          this.processSelfCoachingDisabled = false
        }
      } else {
        if (this.documentcheck){
          this.processSelfCoaching = false;
          this.processSelfCoachingDisabled = true
        } else {
          this.processSelfCoachingDisabled = false
        }
      }
    },
    autoUpdateProcessSelf(updatedString) {
      if (this.coaching && this.documentcheck) {
        if (reactiveStorage.user.permissions.insurance.advocardPrivateDataType.Dokumentencheck && reactiveStorage.user.permissions.insurance.advocardPrivateDataType.Coaching) {
          if (updatedString === "DokumentenCheck") {
            this.processSelfCoaching = this.processSelf;
          } else if (updatedString === "Coaching") {
            this.processSelf = this.processSelfCoaching;
          }
        }
      }
    },

    checkForFachberatung() {
      if (this.activityType.id === "7") {
        this.fachberatungVisible = true;
      } else {
        this.fachberatungVisible = false;
        this.fachberatung = false;
        this.fachberatungProcessSelf = false;
      }
    },

    // disable sent button so prevent multiple entries
    blockButtonWhileRequestAndForm() {
      if (this.valid === false) {
        return true
      }
      return this.isLoading === true;
    },
    checkForNulling() {
      this.serviceNumberCanBeNull = false
      this.policyNumberCanBeNull = false
      if (/^\d[.]\d{3}[.]\d{3}[.]\d$/.test(this.policyNumber) && this.serviceNumber.length === 0 && this.policyNumber !== '0.000.000.0') {
        this.serviceNumberCanBeNull = true
      } else {
        this.policyNumberChecked = false
      }
      if (/^\d{2}-\d{6}-\d$/.test(this.serviceNumber) && this.policyNumber.length === 0 && this.serviceNumber !== '00-000000-0') {
        this.policyNumberCanBeNull = true
      } else {
        this.serviceNumberChecked = false
      }
    },
    // Form Data Request
    async sendData() {
      // set Loading true
      this.isLoading = true;

      // init Request Data
      const formData = {
        "contractNumber": this.policyNumber,
        "customerName": this.name,
        "notesDocument": this.notes,
        "notesMediation": this.notesSM,
        "notesFachberatung": this.notesFB,
        "customerPhoneNumber": this.phone,
        "record": this.issue,
        "customerMailAddress": this.email,
        "serviceType": this.activityType.id,
        "serviceTypeName": this.activityType.name,
        "consultationDate": this.consultationDate,
        "customerLastname": this.lastName,
        "serviceNumber": this.serviceNumber,
      };
      //  if Session isValid
      await sessionHandler();

      // send add to Database request
      await this.$http
          .post(
              getAdvocardPrivateURL(false),
              formData
              , {
                mode: requestMode(),
                params: {
                  "documentCheckProcessSelf": this.processSelf,
                  "fachberatungProcessSelf": this.fachberatungProcessSelf,
                  "shuttleMediationProcessSelf": this.processSelfMediation,
                  "coachingProcessSelf": this.processSelfCoaching,
                  "sendSMS": this.sms,
                  "documentCheck": this.documentcheck,
                  "fachberatung": this.fachberatung,
                  "shuttleMediation": this.shuttlemediation,
                  "coaching": this.coaching,
                },
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              this.clearRecoverData();

              if (this.chevalierVermittlung){
                this.submitToChev(response.data.id).then(() => {
                  showAlert(i18n.t('success.form'), "success");
                  this.$router.push("/")
                })
              } else {
                showAlert(i18n.t('success.form'), "success");
                this.$router.push("/")
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "AdvocardPrivate-sD1", "error");
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              } else {
                showAlert(i18n.t('error.api.undefined') + "AdvocardPrivate-sD2", "error");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "AdvocardPrivate-sD2", "error");
            }
          })
          .finally(() => {
            this.isLoading = false;
          })
    },

    async submitToChev(openCaseId) {
      await sessionHandler();
      await this.$http
          .patch(
              getAdvocardPrivateURL(true) + "/" + openCaseId + "/TransmitToExternalServiceProvider",
              {
                "notes": this.submitReport,
              }
              , {
                mode: requestMode(),
                params: {
                  "externalServiceProvider": "Chevalier"
                },
                headers: {
                  'Accept': 'application/json',
                  'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                },
              }
          )
          .then((response) => {
            if (response.status === 200) {
              showAlert("Der Fall wurde erfolgreich übermittelt", "info");
              this.submitDialog = false;
              this.getCases();
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                showAlert(i18n.t('warning.unauthorised'), "warning");
              }
            } else {
              showAlert(i18n.t('error.api.undefined') + "Calendar-oCC-2", "error");
            }
          })
          .finally(() => {
            this.isLoadingBill = false;
          })
    },

    // send button pressed
    validate() {
      if (this.$refs.form.validate()) {
        this.sendData();
      }
    },

    // Save to localStorage
    save() {
      let store = {
        "email": this.email,
        "documentcheck": this.documentcheck,
        "shuttlemediation": this.shuttlemediation,
        "processSelf": this.processSelf,
        "processSelfMediation": this.processSelfMediation,
        "notesDocument": this.notes,
        "notesMediation": this.notesSM,
        "sms": this.sms,
        "submitReport": this.submitReport,
        "chevalierVermittlung": this.chevalierVermittlung,
        "customerPhoneNumber": this.phone,
        "policyNumber": this.policyNumber,
        "activityType": this.activityType,
        "namePolicyNumber": this.namePolicyNumber,
        "serviceNumberChecked": this.serviceNumberChecked,
        "policyNumberChecked": this.policyNumberChecked,
        "issue": this.issue,
        "consultationDate": this.consultationDate,
        "name": this.name,
        "lastName": this.lastName,
        "serviceNumber": this.serviceNumber,
        "fachberatungVisible": this.fachberatungVisible,
        "fachberatung": this.fachberatung,
        "fachberatungProcessSelf": this.fachberatungProcessSelf,
        "notesFB": this.notesFB,
      }
      localStorage.setItem('AdvocardPrivate', JSON.stringify(store));

    },
    saveConsultationDate() {
      this.save();
      this.consultationDateMenu = false;
    },

    //Hide policyNumber/claimNumber
    hideNumbers(otherNumber) {
      if (this.free) {
        return false;
      }
      if (otherNumber === '') {
        return true;
      }
    },

    // Clear localStorage
    clearRecoverData() {
      localStorage.setItem('AdvocardPrivate', "")
      this.dialog = false;
    },

    // Set localStorage to Formfields
    recoverData() {
      let recoverableData = localStorage.getItem('AdvocardPrivate');
      recoverableData = JSON.parse(recoverableData);
      this.email = recoverableData.email;
      this.documentcheck = recoverableData.documentcheck;
      this.shuttlemediation = recoverableData.shuttlemediation;
      this.notes = recoverableData.notesDocument;
      this.notesSM = recoverableData.notesMediation;
      this.sms = recoverableData.sms;
      this.phone = recoverableData.customerPhoneNumber;
      this.processSelf = recoverableData.processSelf;
      this.processSelfMediation = recoverableData.processSelfMediation;
      this.serviceNumberChecked = recoverableData.serviceNumberChecked;
      this.policyNumberChecked = recoverableData.policyNumberChecked;
      this.policyNumber = recoverableData.policyNumber;
      this.activityType = recoverableData.activityType;
      this.namePolicyNumber = recoverableData.namePolicyNumber;
      this.issue = recoverableData.issue;
      this.consultationDate = recoverableData.consultationDate;
      this.name = recoverableData.name;
      this.lastName = recoverableData.lastName;
      this.serviceNumber = recoverableData.serviceNumber;
      this.fachberatungVisible = recoverableData.fachberatungVisible;
      this.fachberatung =  recoverableData.fachberatung;
      this.fachberatungProcessSelf = recoverableData.fachberatungProcessSelf;
      this.notesFB = recoverableData.notesFB;
      this.submitReport = recoverableData.submitReport;
      this.chevalierVermittlung = recoverableData.chevalierVermittlung;
      this.dialog = false;
      this.checkForNulling();
    },

    //  if there is data to recover
    checkRecoverData() {
      let recoverableData = localStorage.getItem('AdvocardPrivate');
      if (recoverableData) {
        this.dialog = true;
      }
    },

    // format Date
    formatDate(unformedDate) {
      return dateFormatter(unformedDate);
    }
  },
  computed: {
    errorMessage() {
      return JSON.parse(JSON.stringify(this.$t('error.validation')));
    },
    inputLabels() {
      return JSON.parse(JSON.stringify(this.$t('insurance.AdvocardPrivate.inputField')));
    },
    //Form Rules
    emailRules() {
      return [
        v => /^\S+@\S+\.\S+$/.test(v) || this.errorMessage["validEmail"],
        v => !!v || this.errorMessage.required,
      ]
    },
    policyNumberRules() {
      return [
        v => /^\d[.]\d{3}[.]\d{3}[.]\d$/.test(v) || this.errorMessage["policyNumberError"],
        v => !!v || this.errorMessage.required,
      ]
    },
    serviceNumberRules() {
      return [
        v => /^\d{2}-\d{6}-\d$/.test(v) || this.errorMessage["serviceNumberError"],
        v => !!v || this.errorMessage.required,
      ]
    },
    activityTypeRules() {
      return [
        v => !!v || this.errorMessage.required,
      ]
    },
    namePolicyNumberRules() {
      return [
        v => !!v || this.errorMessage.required,
        v => (v && v.length <= 150) || this.errorMessage["maxLength150"],
      ]
    },
    notesRules() {
      return [
        v => !!v || this.errorMessage.required,
        v => (v && v.length <= 300) || this.errorMessage["maxLength300"],
      ]
    },
    reportRules() {
      return [
        v => !!v || this.errorMessage.required,
        v => (v && v.length <= 8000) || this.errorMessage["maxLength8000"],
      ]
    },
    phoneRules() {
      return [
        v => /\+[0-9]{2}[1-9][0-9]{1,25}/.test(v) || this.errorMessage["validPhone"],
        v => !!v || this.errorMessage.required,
      ]
    },
    issueRules() {
      return [
        v => !!v || this.errorMessage.required,
        v => (v && v.length <= 500) || this.errorMessage["maxLength500"],
      ]
    },
    nameRules() {
      return [
        v => !!v || this.errorMessage.required,
      ]
    },
  },
  mounted() {
    this.checkRecoverData();
  }
}
</script>

<style lang="stylus" scoped>
.formBody
  margin-top 3vh

.form
  margin-top 1vh

</style>
<!-- Hide Number Button -->
<style>

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
